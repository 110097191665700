import { account24SolidIcon } from './account24-solid';
import { account24Icon } from './account24';
import { adminIcon } from './admin';
import { alarmBellIcon } from './alarm-bell';
import { alert24SolidIcon } from './alert24-solid';
import { alert24Icon } from './alert24';
import { assign24SolidIcon } from './assign24-solid';
import { assign24Icon } from './assign24';
import { benchmarking24SolidIcon } from './benchmarking24-solid';
import { benchmarking24Icon } from './benchmarking24';
import { calendarIcon } from './calendar';
import { calendar24SolidIcon } from './calendar24-solid';
import { calendar24Icon } from './calendar24';
import { configuration24SolidIcon } from './configuration24-solid';
import { configuration24Icon } from './configuration24';
import { costAnalysis24SolidIcon } from './cost-analysis24-solid';
import { costAnalysis24Icon } from './cost-analysis24';
import { designSystemIcon } from './design-system';
import { eventLog24SolidIcon } from './event-log24-solid';
import { eventLog24Icon } from './event-log24';
import { executiveOverview24SolidIcon } from './executive-overview24-solid';
import { executiveOverview24Icon } from './executive-overview24';
import { exitIcon } from './exit';
import { expand24SolidIcon } from './expand24-solid';
import { expand24Icon } from './expand24';
import { help24SolidIcon } from './help24-solid';
import { help24Icon } from './help24';
import { incidents24SolidIcon } from './incidents24-solid';
import { incidents24Icon } from './incidents24';
import { insight24SolidIcon } from './insight24-solid';
import { insight24Icon } from './insight24';
import { logout16Icon } from './logout16';
import { map24SolidIcon } from './map24-solid';
import { map24Icon } from './map24';
import { organization24SolidIcon } from './organization24-solid';
import { organization24Icon } from './organization24';
import { overview24SolidIcon } from './overview24-solid';
import { overview24Icon } from './overview24';
import { passwordIcon } from './password';
import { processConfig24SolidIcon } from './process-config24-solid';
import { processConfig24Icon } from './process-config24';
import { processReporting24SolidIcon } from './process-reporting24-solid';
import { processReporting24Icon } from './process-reporting24';
import { processes24SolidIcon } from './processes24-solid';
import { processes24Icon } from './processes24';
import { reports24SolidIcon } from './reports24-solid';
import { reports24Icon } from './reports24';
import { resources24SolidIcon } from './resources24-solid';
import { resources24Icon } from './resources24';
import { runtime24SolidIcon } from './runtime24-solid';
import { runtime24Icon } from './runtime24';
import { schedule24SolidIcon } from './schedule24-solid';
import { schedule24Icon } from './schedule24';
import { sessions24SolidIcon } from './sessions24-solid';
import { sessions24Icon } from './sessions24';
import { shieldCheck24SolidIcon } from './shield-check24-solid';
import { shieldCheck24Icon } from './shield-check24';
import { simulator24SolidIcon } from './simulator24-solid';
import { simulator24Icon } from './simulator24';
import { statistics24SolidIcon } from './statistics24-solid';
import { statistics24Icon } from './statistics24';
import { tenantIcon } from './tenant';
import { tenant24Icon } from './tenant24';
import { utilization24SolidIcon } from './utilization24-solid';
import { utilization24Icon } from './utilization24';
import { variantAnalysis24SolidIcon } from './variant-analysis24-solid';
import { variantAnalysis24Icon } from './variant-analysis24';
export const navigationIcons = [account24SolidIcon, account24Icon, adminIcon, alarmBellIcon, alert24SolidIcon, alert24Icon, assign24SolidIcon, assign24Icon, benchmarking24SolidIcon, benchmarking24Icon, calendarIcon, calendar24SolidIcon, calendar24Icon, configuration24SolidIcon, configuration24Icon, costAnalysis24SolidIcon, costAnalysis24Icon, designSystemIcon, eventLog24SolidIcon, eventLog24Icon, executiveOverview24SolidIcon, executiveOverview24Icon, exitIcon, expand24SolidIcon, expand24Icon, help24SolidIcon, help24Icon, incidents24SolidIcon, incidents24Icon, insight24SolidIcon, insight24Icon, logout16Icon, map24SolidIcon, map24Icon, organization24SolidIcon, organization24Icon, overview24SolidIcon, overview24Icon, passwordIcon, processConfig24SolidIcon, processConfig24Icon, processReporting24SolidIcon, processReporting24Icon, processes24SolidIcon, processes24Icon, reports24SolidIcon, reports24Icon, resources24SolidIcon, resources24Icon, runtime24SolidIcon, runtime24Icon, schedule24SolidIcon, schedule24Icon, sessions24SolidIcon, sessions24Icon, shieldCheck24SolidIcon, shieldCheck24Icon, simulator24SolidIcon, simulator24Icon, statistics24SolidIcon, statistics24Icon, tenantIcon, tenant24Icon, utilization24SolidIcon, utilization24Icon, variantAnalysis24SolidIcon, variantAnalysis24Icon];
